<template>
    <div class="mt20">
        <Carrier ref="carrierComponent" />
        <div
            class="md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
        >
            <Route
                name="routeCompontent"
                @loading="loading"
                @featch="getCarrier"
            />

            <div class="loading-overlay" v-if="showLoading">
                <div class="wrapper">
                    <md-progress-spinner
                        md-mode="indeterminate"
                        :md-stroke="2"
                    />
                </div>
            </div>
        </div>

        <iframe
            id="downloadIframe"
            name="downloadIframe"
            style="display:none;"
        ></iframe>
    </div>
</template>

<script>
import Route from '../components/Route/main';
import Carrier from '../components/elements/Carrier';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    components: {
        Route,
        Carrier
    },
    data() {
        return {
            showLoading: true
        };
    },

    computed: {
        ...mapGetters({
            websiteIsOpen: 'settings/websiteOpen'
        })
    },

    methods: {
        ...mapMutations({
            websiteOpen: 'settings/WEBSITE_OPEN'
        }),
        ...mapActions({
            getCarrier: 'auth/validate',
            refreshToken: 'auth/refreshToken'
        }),

        scrollToTop() {
            window.scrollTo(0, 0);
        },

        loading(e) {
            this.showLoading = e;
        }
    },

    async mounted() {
        this.getCarrier();

        if (!this.websiteIsOpen) {
            await this.refreshToken();
            this.websiteOpen(true);
        }

        this.scrollToTop();
    }
};
</script>
